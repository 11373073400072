import React from 'react';
import '../styles/WelcomeOverlay.css';

const WelcomeOverlay = ({ onClose }) => {
    return (
        <div className="welcome-overlay">
            <div className="welcome-content">
                <h1>Welcome to EarlyON Events Locator</h1>
                <p>Discover EarlyON events near you on Google Map</p>
                <ul>
                    <li>Browse events on the map</li>
                    <li>Filter by date, type, and age group</li>
                    <li>Click on markers for event details</li>
                </ul>
                <p>Start exploring to find activities for you and your child!</p>
                <p>The website is still in the development stage, currently covering York, Peel, Niagara and a few other regions.</p>
                <p>If you have any feedbacks or suggestions, or want to report any issues/bugs, please reach out through FindEarlyOn@gmail.com.</p>
                <button className="get-started-button" onClick={onClose}>Get Started</button>
            </div>
        </div>
    );
};

export default WelcomeOverlay;
