import React, { useState, useCallback, useMemo, useRef } from 'react';
import { GoogleMap, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api';
import CustomInfoWindow from './CustomInfoWindow';
import manualMarkers from '../data/manual_markers.json';
import libraryIcon from '../assets/icons/local_library.svg';
import '../styles/Map.css';

const Map = React.memo(({ events, center, showLibraryEvents }) => {
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedManualMarker, setSelectedManualMarker] = useState(null);
    const [locationEvents, setLocationEvents] = useState([]);
    const mapRef = useRef(null);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

    const mapStyles = useMemo(() => ({
        height: "100vh",
        width: "100%"
    }), []);

    const handleMarkerClick = useCallback((event) => {
        const filteredEvents = events.filter(e => 
            e['Latitude'] === event['Latitude'] && e['Longitude'] === event['Longitude']
        );
        setLocationEvents(filteredEvents);
        setSelectedEvent(filteredEvents[0]);
        setSelectedManualMarker(null);
    }, [events]);

    const handleManualMarkerClick = useCallback((marker) => {
        setSelectedManualMarker(marker);
        setSelectedEvent(null);
        setLocationEvents([]);
    }, []);

    const handleMapClick = useCallback(() => {
        setSelectedEvent(null);
        setSelectedManualMarker(null);
        setLocationEvents([]);
    }, []);

    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    const memoizedMarkers = useMemo(() => events.map((event, index) => (
        <Marker
            key={`${event['Latitude']}-${event['Longitude']}-${index}`}
            position={{ lat: event['Latitude'], lng: event['Longitude'] }}
            onClick={() => handleMarkerClick(event)}
        />
    )), [events, handleMarkerClick]);

    const memoizedManualMarkers = useMemo(() => {
        if (!isLoaded) return null; 

        return manualMarkers.manual_markers.map((marker, index) => (
            <Marker
                key={`manual-${index}`}
                position={{ 
                    lat: marker.Latitude, 
                    lng: marker.Longitude 
                }}
                onClick={() => handleManualMarkerClick(marker)}
                icon={{
                    url: libraryIcon,
                    scaledSize: new window.google.maps.Size(32, 32),
                    anchor: new window.google.maps.Point(16, 16),
                }}
            />
        ));
    }, [handleManualMarkerClick, isLoaded]);

    if (!isLoaded) {
        return <div>Loading...</div>; // 显示加载状态
    }

    return (
        <div className="map-container">
            {/* <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}> */}
                <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={12}
                    center={center}
                    options={{ disableDefaultUI: true, gestureHandling: 'cooperative' }}
                    onClick={handleMapClick}
                    onLoad={onMapLoad}
                >
                    {memoizedMarkers}
                    {showLibraryEvents && memoizedManualMarkers}

                    {selectedEvent && (
                        <CustomInfoWindow
                            event={selectedEvent}
                            position={{ lat: selectedEvent['Latitude'], lng: selectedEvent['Longitude'] }}
                            onCloseClick={() => setSelectedEvent(null)}
                            locationEvents={locationEvents}
                            setSelectedEvent={setSelectedEvent}
                        />
                    )}

                    {selectedManualMarker && (
                        <InfoWindow
                            position={{ 
                                lat: selectedManualMarker.Latitude, 
                                lng: selectedManualMarker.Longitude 
                            }}
                            onCloseClick={() => setSelectedManualMarker(null)}
                        >
                            <div className="info-window-content">
                                <h3>{selectedManualMarker.Name}</h3>
                                <p>
                                    <a 
                                        href={selectedManualMarker.URL} 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                    >
                                        Show Events
                                    </a>
                                </p>
                            </div>
                        </InfoWindow>
                    )}
                </GoogleMap>
            {/* </LoadScript> */}
        </div>
    );
});

export default Map;